import * as React from "react"
import styled from "styled-components"
import Button from "./button"

const colors = {
  defaultBlue: "#B0ECFB",
  lightGreen: "#DDF5E6",
  wasabi: "#D7FBC5",
  yellow: "#E7FC94",
  sand: "#FCDEBC",
  pink: "#FCE1E1",
  purple: "#B7B2FB"
}

const CategoryButton = ({color, title, linkTo, disabled}) => {
  return (
    <CategoryLink
      title={title}
      linkTo={linkTo}
      color={colors[color] || colors.defaultBlue}
      disabled={disabled}
    />
  )
}

export default CategoryButton

const CategoryLink = styled(Button)`
  margin: 16px 4px;
  margin-bottom: 0;
  padding: 6px 8px;
  text-transform: capitalize;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 1;
  transform: translateZ(0) scale(1);
  transition: transform 0.25s ease-in-out;
  background-color: ${props => props.color};
  ${props => props.disabled ? 'pointer-events: none;' : ''}
  
  &:hover {
    transform: translateZ(0) scale(1.2);
  }
`