import * as React from "react"
import {Link} from "gatsby"
import ReactMarkdown from 'react-markdown'
import styled from "styled-components"
import { H3, ArticleContent } from "./text"
import arrowIcon from "../../images/arrow-right.svg"
import CategoryButton from "./categoryButton"

const ArticleItem = ({article, locale, readMore}) => {
  const image = article.mainImage.data.attributes.formats.medium

  return (
    <Container>

      <ArticleImageContainer>
        <Link to={'/' + locale + '/blog/' + article.slug + '/'} style={{width: "100%"}}>
          <ArticleImage src={image.url} width={image.width} height={image.height} alt={article.title} />
        </Link>
      </ArticleImageContainer>
      <Categories>
        {article.categories.data.map(category => {
          return (
            <CategoryButton
              key={category.attributes.slug + '_' + article.id}
              title={category.attributes.name}
              linkTo={'/' + locale + '/blog/category/' + category.attributes.slug + '/'}
              color={category.attributes.style}
              disabled={locale !== 'en'}
            />
          )
        })}
      </Categories>
      <Link to={'/' + locale + '/blog/' + article.slug + '/'} style={{textDecoration: "none"}}><H3 strong={true} mt={16} mb={-8}>{article.title}</H3></Link>
      <ArticleContent>
        <ReactMarkdown>{article.lead}</ReactMarkdown>
      </ArticleContent>
      <ReadMoreLink to={'/' + locale + '/blog/' + article.slug + '/'}><span>{readMore}</span><img src={arrowIcon} alt={""} /></ReadMoreLink>
    </Container>
  )
}

export default ArticleItem

const Container = styled.div`
  width: 490px;
  margin-bottom: 48px;
`

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: -6px;
`

const ArticleImageContainer = styled.div`
  width: 100%;
  background-color: #B0ECFB;
  padding-bottom: 16px;
  display: flex;
`

const ArticleImage = styled.img`
  width: 100%;
  height: 240px;
  object-fit: cover;
  object-position: center;
`

const ReadMoreLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  color: #000000;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  
  img {
    margin-left: 8px;
    transform: translate3d(0, 1px, 0);
    transition: transform 0.35s ease-in-out;
  }
  
  &:hover {
    img {
      transform: translate3d(80%, 1px, 0);
    }
  }
`