import * as React from "react"
import dayjs from "dayjs"
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/pl'
import 'dayjs/locale/it'
import ReactMarkdown from 'react-markdown'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ArticleContent, H2, P } from "../components/contentComponents/text"
import styled from "styled-components"
import NarrowContent from "../components/visualContainers/narrowContent"
import fbIcon from "../images/fb-share.svg"
import twitterIcon from "../images/twitter-share.svg"
import linkedinIcon from "../images/linkedin-share.svg"
import emailIcon from "../images/email-share.svg"
import linkIcon from "../images/link-share.svg"
import { useState } from "react"
import ReadMoreSection from "../components/contentComponents/readMoreSection"
import CategoryButton from "../components/contentComponents/categoryButton"
import linkArrowIcon from "../images/arrow-right.svg"
import { Link } from "gatsby"
import HTMLRenderer from 'react-html-renderer'

const appendScript = function(code) {
  if (typeof document !== `undefined`) {
    let s = document.createElement('script');
    s.type = 'text/javascript';
    try {
      s.appendChild(document.createTextNode(code));
      document.body.appendChild(s);
    } catch (e) {
      s.text = code;
      document.body.appendChild(s);
    }
  }
}

const ArticlePage = (props) => {
  const t = props.pageContext.pageData
  const { site, locale, pageName, articles, fullPath } = props.pageContext
  const { categories, blocks } = t
  const author = t.author.data?.attributes
  const image = t.mainImage.data.attributes.formats.medium
  const datePublished = dayjs(t.date).locale(locale).format('DD.MM.YYYY')
  const shareUrl = process.env.GATSBY_SITE_URL + props.path

  const [linkCopied, setLinkCopied] = useState(false)

  return (
    <Layout site={site} language={locale} pagePath={props.path} pageName={pageName}>
      <Seo title={t.title} lang={locale} pagePath={fullPath} site={site} meta={t.seo} isArticle={true} />
      <NarrowContent className="center-vertical" extraNarrow={true}>
        <AllPostsLink to={"/" + locale + "/blog/"}><img src={linkArrowIcon} alt={""} /><span>{site.globals.readMoreSection.allPostsButtonTitle}</span></AllPostsLink>
        <Categories>
          {categories.data.map(category => {
            return (
              <CategoryButton
                key={category.attributes.slug + '_' + t.id}
                title={category.attributes.name}
                linkTo={'/' + locale + '/blog/category/' + category.attributes.slug + '/'}
                color={category.attributes.style}
                disabled={locale !== 'en'}
              />
            )
          })}
        </Categories>

        <P mt={16} mb={4}>{datePublished}</P>
        <H2 strong={true}><span >{t.title}</span></H2>
        <ArticleImageContainer>
          <ArticleImage src={image.url} width={image.width} height={image.height} alt={t.title}/>
        </ArticleImageContainer>
        <Author>
          {author && (
            <div className="center-vertical">
              {author.photo?.data?.attributes &&
              <AuthorImageContainer>
                <AuthorImage src={author.photo.data.attributes.formats.thumbnail.url} alt={author.name}/>
              </AuthorImageContainer>
              }
              <P mt={author.photo?.data?.attributes ? 6 : 32} mb={4} small={true} strong={true}>{author.name}</P>
              <AuthorDivider />
              <P mt={4} small={true}>{author.position}</P>
            </div>
          )}

        </Author>

        <ArticleContent>
          <ContentContainer>
            {blocks.map((block, index) => {
              return(
                <div key={index}>
                  {block.__component === "articleblock.article-block-text" && (<ReactMarkdown linkTarget={(href) => { return href.includes('paywerk.co') || href.includes('paywerk.dev') ? '_self' : 'blank' }}>{block.text}</ReactMarkdown>)}
                  {block.__component === "articleblock.java-script" && appendScript(block.code)}
                  {block.__component === "articleblock.article-block-html" && (
                    <HTMLRenderer
                      html={block.htmlCode}
                      components={{
                        a: props => <a target={props.target ? props.target : (props.href.includes('paywerk.co') || props.href.includes('paywerk.dev') ? 'blank' : '_self')} {...props} />
                      }}
                    />
                  )}
                </div>
              )
            })}
          </ContentContainer>
        </ArticleContent>
        <ShareButtons className="center">
          <FacebookShareButton
            url={shareUrl}
            className="share-button"
          >
            <ShareIcon className="" src={fbIcon} alt="Facebook" />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            className="share-button"
          >
            <ShareIcon className="" src={twitterIcon} alt="Twitter" />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
            className="share-button"
          >
            <ShareIcon className="" src={linkedinIcon} alt="LinkedIn" />
          </LinkedinShareButton>
          <EmailShareButton
            url={shareUrl}
            className="share-button"
          >
            <ShareIcon className="" src={emailIcon} alt="Email" />
          </EmailShareButton>
          <CopyLinkButton
            className="share-button"
            onClick={() => {
              navigator.clipboard.writeText(shareUrl)
              setLinkCopied(true)
            }}
          >
            <ShareIcon className="" src={linkIcon} alt="Link" />
            {linkCopied && <LinkCopiedMessage small={true}>{site.globals.share.linkCopied}</LinkCopiedMessage>}
          </CopyLinkButton>
        </ShareButtons>
      </NarrowContent>

      <ReadMoreSection articles={articles} exclude={t.slug} t={site.globals.readMoreSection} locale={locale}/>
    </Layout>
  );
}

export default ArticlePage

const AllPostsLink = styled(Link)`
  display: flex;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  color: #000000;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  margin-top: -16px;
  margin-bottom: 24px;
  width: 100%;
  text-align: left;
  
  img {
    margin-right: 8px;
    transform: translate3d(0, 1px, 0) rotate(180deg);
    transition: transform 0.35s ease-in-out;
  }
  
  &:hover {
    img {
      transform: translate3d(-80%, 1px, 0) rotate(180deg);
    }
  }
`

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ArticleImageContainer = styled.div`
  width: 100%;
  background-color: #B0ECFB;
  padding-top: 32px;
  padding-bottom: 16px;
  display: flex;
  margin-top: -16px;
  overflow: hidden;
`

const ArticleImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 320px;
  object-fit: cover;
  object-position: center;
`

const Author = styled.div`
  margin-top: -16px;
  margin-bottom: 32px;
`

const AuthorImageContainer = styled.div`
  width: 80px;
  height: 80px;
  padding: 10px;
  border-radius: 50%;
  background-color: #B0ECFB;
`

const AuthorImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`

const AuthorDivider = styled.div`
  width: 80px;
  height: 2px;
  background-color: #B0ECFB;
`

const ContentContainer = styled.div`
  padding-bottom: 16px;
  border-bottom: solid 2px #B0ECFB;
  margin-bottom: 20px;
`

const ShareButtons = styled.div`
  align-items: center;
  
  .share-button {
    margin: 12px;
  }
`

const ShareIcon = styled.img`
  
`

const CopyLinkButton = styled.button`
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
`

const LinkCopiedMessage = styled(P)`
  position: absolute;
  right: -8px;
  top: 0;
  transform: translateX(100%);
  white-space: nowrap;
`