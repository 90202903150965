import * as React from "react"
import {Link} from "gatsby"
import styled from "styled-components"
import { H2 } from "./text"
import arrowIcon from "../../images/arrow-right.svg"
import ArticleItem from "./articleItem"

const ReadMoreSection = ({articles, exclude, t, locale}) => {
  let found = 0
  let index = 0
  const selectedArticles = []

  while(found < 2 && index <= articles.length) {
    if(articles[index]?.slug && articles[index]?.slug !== exclude) {
      selectedArticles.push(articles[index])
      found++
    }
    index++
  }

  return (
    <Section>
      <TitleRow>
        <H2 strong={true}>{t.title}</H2>
        {locale === "en" && <AllPostsLink to={"/" + locale + "/blog/"}><span>{t.allPostsButtonTitle}</span><img src={arrowIcon} alt={""} /></AllPostsLink>}
      </TitleRow>
      <ArticleGrid>
        {selectedArticles?.map((article, index) => <ArticleItem key={index} article={article} locale={locale} readMore={t.readMoreButtonTitle}/>)}
      </ArticleGrid>
    </Section>
  )
}

export default ReadMoreSection

const Section = styled.div`
  width: 100%;
  margin-top: 120px;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const AllPostsLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  color: #000000;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  
  img {
    margin-left: 8px;
    transform: translate3d(0, 1px, 0);
    transition: transform 0.35s ease-in-out;
  }
  
  &:hover {
    img {
      transform: translate3d(80%, 1px, 0);
    }
  }
`

const ArticleGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  flex-flow: row wrap;
  margin-top: 36px;  
`